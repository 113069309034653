import React from 'react';
import SEO from '../components/seo';
import Subscribed from '../containers/Subscribed';
import { ResetCSS } from 'common/src/assets/css/style';

const SubscribedPage = () => (
  <>
    <SEO title="Subscribed" />
    <ResetCSS />
    <Subscribed></Subscribed>
  </>
);

export default SubscribedPage;
